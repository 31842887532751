<script>
// wow.js
const WOW = require('wow.js');
import 'animate.css';

// gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// scroll-hint
import ScrollHint from 'scroll-hint';
import 'scroll-hint/css/scroll-hint.css';

// vue components
import gotop from './components/Gotop.vue';
import aco from './components/Aco.vue';
import pieChart_01 from './components/PiepChart_01.vue';
import pieChart_02 from './components/PiepChart_02.vue';
import pieChart_03 from './components/PiepChart_03.vue';
import pieChart_04 from './components/PiepChart_04.vue';
import pieChart_05 from './components/PiepChart_05.vue';
import textLeader from './components/TextLeader.vue';
import barLine from './components/BarLine.vue';

// vue components（フォーム関係）
import btnsubmit from './components/BtnSubmit.vue'; //送信ボタン
import formdeleteinvalid from './components/FormDeleteInvalid.vue'; //入力があったらinvalidクラス削除


export default {
    inject: ["mq"],

    components: {
        gotop,
        aco,
        pieChart_01,
        pieChart_02,
        pieChart_03,
        pieChart_04,
        pieChart_05,
        btnsubmit,
        formdeleteinvalid,
        textLeader,
        barLine,
    },

    data() {
        return {
            headerH: 0, //ヘッダーの高さ
            gnavActive: false, //グロナビアクティブ管理
            isOpenAccordion: false, //グロナビ：SP時サブメニューのアコーディオン
            formset: '', //フォームの出し分けに使用
            fileSelected: { //フォーム:添付ファイルをいれたかチェック
                input1: false,
                input2: false,
                input3: false
            },
        }
    },

    methods: {
        // wow.js初期化
        wowInit() {
            const wow = new WOW({
                offset: this.mq.mdMinus ? 100 : 200, //SP : PC
                animateClass: 'animate__animated'
            });
            wow.init();
        },

        // ヘッダーの高さを取得
        getHeaderH() {
            const header = this.$refs.header;

            const func = () => {
                this.headerH = header.getBoundingClientRect().height;
            }

            window.addEventListener('load', func);
            window.addEventListener('resize', func);
        },

        // URLにハッシュがあればページ読み込み時にスクロール
        loadScroll() {
            const hash = location.hash;
            if (hash && hash.match(/^#.+$/)) {
                window.onload = () => {
                    this.$scrollTo(hash, 500, {
                        offset: -this.headerH
                    });
                }
            }
        },

        // アンカーリンク
        anchorLink(hash) {
            if (!document.getElementById(hash)) return;
            this.$scrollTo(`#${hash}`, 500, {
                offset: -this.headerH, //ヘッダー分ずらす
                onStart: this.gnav.isActive = false //グロナビを閉じる
            });
        },

        //連続フェードインアップ
        gsapSeriesFadeInUp(){
            const trigger = document.querySelector('.gsap-seriesFadeInUp-trigger');
            const groups = gsap.utils.toArray('.gsap-seriesFadeInUp-group');

            if (groups) {
                groups.forEach((group) => {
                    const boxes = gsap.utils.toArray(group.querySelectorAll('.gsap-seriesFadeInUp'));

                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: trigger,
                            start: 'top bottom-=200',
                            markers: false,
                        }
                    });

                    tl.from(boxes, {
                        opacity: 0,
                        y: 40,
                        ease: "power2.Out",
                        stagger: {
                        each: 0.4,
                        },
                    });

                });
            }
        },

        // haderスクロールアニメーション
        gsapHeaderResize() {
            const headerChangePoint = document.querySelector('.header-change-point');
            const header = document.querySelector('.header');
            const headerFlex = document.querySelector('.header__flex');
            const mvScroll = document.querySelector('.scroll-point');

            //headerをfixedにする
            gsap.to(header, {
                scrollTrigger: {
                    trigger: headerChangePoint,
                    start: 'top top',
                    end: 'bottom top',
                    toggleActions: "play none reverse none",
                    onEnter: () => header.classList.add('is-fixed'),
                    onEnterBack: () => header.classList.remove('is-fixed'),
                    markers: false,
                }
            });
            //headerにtransitionを付与
            gsap.to(header, {
                scrollTrigger: {
                    trigger: headerChangePoint,
                    start: 'top+=100px top',
                    end: 'bottom+=100px top',
                    toggleActions: "play none reverse none",
                    onEnter: () => header.classList.add('is-transition'),
                    onEnterBack: () => header.classList.remove('is-transition'),
                    markers: false,
                }
            });
            //headerを出現させる
            gsap.to(headerFlex, {
                scrollTrigger: {
                    trigger: mvScroll,
                    start: 'top top',
                    end: 'bottom top',
                    toggleActions: "play none reverse none",
                    onEnter: () => header.classList.add('is-scroll'),
                    onEnterBack: () => header.classList.remove('is-scroll'),
                    markers: false,
                }
            });
        },

        //カウントアップアニメーション
        gsapcountUp(){
            const groups = gsap.utils.toArray('.count-wrap'); //発火対象

            if(groups.length > 0){
                groups.forEach((group) => {
                    const countUpTrigger = group.querySelector('.count'); //発火タイミング
                    const countUpTarget = group.querySelector('.count'); //アニメーション対象
                    var elementDic = {
                        from:parseInt(countUpTrigger.dataset.from),
                        to:parseInt(countUpTarget.dataset.to)
                    }
                    var elementNum = {count:elementDic.from};

                    gsap.to(elementNum,{
                        count:elementDic.to,//カウントさせる数値(終着点)
                        duration:2,//カウントアップさせる秒数
                        ease:'none',//デフォルトでpower(指数)がかかっている
                        scrollTrigger:{
                            trigger:countUpTrigger,// トリガーとなる要素の指定
                            start:'top bottom',// トリガー位置の調整
                            markers:false,// マーカーの表示
                        },
                        onUpdate:()=>{
                            if(Number.isInteger(elementDic.to)){
                                //整数バージョン
                                countUpTarget.textContent = parseFloat(elementNum.count).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });// 数字が変わる度にDOM要素に代入
                            }else{
                                //小数点バージョン
                                countUpTarget.textContent = parseFloat(elementNum.count).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });// 数字が変わる度にDOM要素に代入
                            }
                        }
                    })
                });
            }
        },

        // フォーム：$_SESSIONのお問い合わせ種別をdataに入れる
        initFormset() {
            if (typeof formtype === 'undefined' || !formtype) return;
            this.formset = formtype;
        },
        
        // フォーム：添付ファイルを入れたかチェック
        handleFileChange(inputKey, e) {
            this.fileSelected[inputKey] = true;
        },

        // グロナビ：サブメニューアコーディオン開閉チェック
        toggleAccordion() {
            this.isOpenAccordion = !this.isOpenAccordion;

        },
		beforeEnter(el) {
			el.style.height = '0';
		},
		enter(el) {
            el.style.height = el.scrollHeight + 'px';
		},
		beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px';
		},
		leave(el) {
            el.style.height = '0';
		},
        //ページトップ用読み込み時フェードイン
        topFadein() {
            document.addEventListener('DOMContentLoaded', function() {
                // ページの読み込みが完了したらフェードインさせる要素に.visibleクラスを追加
                var fadeInElements = document.querySelectorAll('.fade-in');
                if(fadeInElements){
                    fadeInElements.forEach(function(element) {
                        element.classList.add('visible');
                    });
                }
            });
        }
    },

    mounted() {
        this.wowInit();
        this.getHeaderH();
        this.loadScroll();
        this.gsapHeaderResize();
        this.gsapSeriesFadeInUp();
        this.gsapcountUp();
        this.initFormset();
        this.topFadein();
        
        new ScrollHint('.js-scrollable', {
            i18n: {
                scrollable: 'スクロールできます'
            }
        });
        
    },

}
</script>