<template>
    <div>{{ truncatedText }}</div>
</template>
  
<script> 
export default {
  props: ['text'],
  computed: {
    truncatedText() {
      const maxLength = 85;
      if (this.text.length > maxLength) {
        return this.text.substring(0, maxLength) + '...';
      } else {
        return this.text;
      }
    }
  }
}
</script>