<template>
    <div class="bar-line">
        <!-- <img src="/assets/img/recruit/our-numbers/pie_01.svg" alt="recruit" width="256" height="210" ref="myImage"> -->
        <img src="/assets/img/recruit/our-numbers/img_uriage.webp" width="164" height="202" alt="コロナ禍でも順調に売上を伸ばしています" ref="myImage">
        <canvas ref="myChart"></canvas>
    </div>
</template>

<script>

// gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Chart.js
import Chart from 'chart.js/auto';

export default {
    inject: ["mq"],

    data() {
        return {
            isActive: false,
            myChart: null
        }
    },

    methods: {

        //円グラフ
        gsapPie(){
            let ctx = this.$refs.myChart;
            let imgElement = this.$refs.myImage;
            gsap.set(imgElement, {opacity: 0});

            if(ctx){
                const ctxArgs = {
                    type: 'bar', // 最初のデータセットのタイプを指定します
                    data: {
                        labels: ['10期', '11期', '12期(前期)', '13期(今期)'],
                        datasets: [{
                            label: '売上高',
                            data: [501786, 539697, 564660, 693000],
                            backgroundColor: [
                                'rgba(143, 143, 143, 1)',
                                'rgba(143, 143, 143, 1)',
                                'rgba(51, 51, 51, 1)',
                                'rgba(0, 163, 109, 1)'
                            ], // 各棒グラフの色を指定します
                        }]
                    },
                    options: {
                        scales: {
                            y: {
                                display: false, // y軸を非表示にします
                            },
                            x: {
                                display: true, // x軸を非表示にします
                                grid: {
                                    display: false // x軸の縦線を非表示にします
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false // 凡例を非表示にします
                            },
                            tooltip: {
                                enabled: false // ツールチップを無効化する
                            },
                        },
                    }
                }
                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: ctx,
                        start: 'top 80%',
                        onEnter: () => {
                            // チャートがまだ作成されていなければ新しいチャートを作成する
                            if (!this.myChart) {
                                if (this.mq.mdMinus) {
                                     //sp
                                    this.myChart = new Chart(ctx, ctxArgs);
                                } else {
                                    //pc
                                    setTimeout(() => {
                                        this.myChart = new Chart(ctx, ctxArgs);
                                    }, 1300);
                                }
                            }
                        },
                        markers: false,
                    }
                });

                if(this.mq.mdMinus){
                    tl.to(ctx, {})
                    .to(imgElement, {
                        opacity: 1,
                        duration: 0.5
                    });
                }else{
                    tl.to(ctx, {})
                    .to(imgElement, {
                        opacity: 1,
                        duration: 0.5
                    }, "+=1.6");
                }
            }
        },
    },

    mounted() {
        this.gsapPie();
    },
}
</script>