<template>
    <div class="pie is-05">
        <img src="/assets/img/recruit/our-numbers/pie_05.svg" alt="recruit" width="169" height="47" ref="myImage">
        <canvas ref="myChart"></canvas>
    </div>
</template>

<script>

// gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Chart.js
import Chart from 'chart.js/auto';

export default {
    data() {
        return {
            isActive: false,
            myChart: null
        }
    },

    methods: {

        //円グラフ
        gsapPie(){
            let ctx = this.$refs.myChart;
            let imgElement = this.$refs.myImage;
            gsap.set(imgElement, {opacity: 0});

            if(ctx){
                const ctxArgs = {
                    type: 'pie',
                    data: {
                        datasets: [{
                        data: [91.5,8.5],
                        backgroundColor: ['#00A56E', '#dbdbdb'],
                        borderWidth: 0,
                        }]
                    },
                    options: {
                        hover: {
                            mode: null  // ホバー時の挙動を無効化する
                        },
                        plugins:{
                            tooltip: {
                                enabled: false // ツールチップを無効化する
                            },
                        },
                        // cutout: 80,
                    }
                }
                let tl = gsap.timeline({
                        scrollTrigger: {
                        trigger: ctx,
                        start: 'top 80%',
                        onEnter: () => {
                            // チャートがまだ作成されていなければ新しいチャートを作成する
                            if (!this.myChart) {
                                this.myChart = new Chart(ctx, ctxArgs);
                            }
                        },
                        markers: false,
                        }
                });

                tl.to(ctx, {})
                    .to(imgElement, {
                        opacity: 1,
                        duration: 0.5
                    }, "+=0.4");
            }
        },
    },

    mounted() {
        this.gsapPie();
    },
}
</script>