<template>
</template>

<script>
export default {
    mounted() {
        // 入力があったらinvalidクラス削除
        const form = document.getElementById('form');
        if(form){
            const inputs = form.querySelectorAll('input, textarea');
            inputs.forEach((input) => {
                input.addEventListener('input', (event) => {
                    event.target.classList.remove('invalid');
                    event.target.setCustomValidity('');
                });
            });
        }
    },
}
</script>